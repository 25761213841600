// Development environment configuration
export const environment = {
  isMockAPI: false,
  isStoreDevToolsEnabled: true,
  isBoundariesEnabled: true,
  isBoundariesCRUDEnabled: true,
  isRfidCardPrintEnabled: true,
  isMonitorOwnershipAndAssignmentEnabled: true,
  isMonitorOwnershipAndAssignmentEnabledMock: false,
  isVendorEmployeeManagementEnabled: true,
  isAnnotatedRideTraceEnabled: true,
  isCampusLocationDeactivation: true,
  isRestrictionsEnabled: true,
};
